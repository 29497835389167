import * as React from "react"

const menuIcon = require("../images/icons/dp-menu-hamburger.svg")
const closeIcon = require("../images/icons/dp-close-white.svg")

const MenuButton = ({ toggle, toggled }) => {
  let opts = {}
  if (toggled) {
    opts["aria-expanded"] = "true"
  }

  return (
    <button
      {...opts}
      className="c-page-header__nav__button"
      aria-haspopup="menu"
      aria-controls="menu"
      aria-label="Klicka för fler menyval"
      onClick={() => {
        toggle()
      }}
      id="menuButton"
    >
      <span className="c-page-header__nav__button__text">Meny</span>
      <img
        className="c-page-header__nav__button__icon c-page-header__nav__button__icon--bars"
        src={menuIcon}
        alt="Öppna meny"
      />
      <img
        className="c-page-header__nav__button__icon c-page-header__nav__button__icon--close"
        src={closeIcon}
        alt="Stäng meny"
      />
    </button>
  )
}

export default MenuButton
