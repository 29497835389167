import React, { useState } from "react"
import HeaderLogo from "./header-logo"
import MenuButton from "./menu-button"
import Menu from "./menu-overlay"
import Link from "gatsby-link"
import speakerIcon from "../images/icons/fflh-dp-icon-speaker.svg"

export default function Header() {
  const [toggleMenu, setToggleMenu] = useState(false)

  const toggleMenuHandler = () => {
    setToggleMenu(!toggleMenu)
  }

  return (
    <>
      <header
        className={`c-page-header ${
          toggleMenu ? "c-page-header--open-menu" : " "
        }`}
        aria-label="Sidhuvud med meny"
      >
        <HeaderLogo modifier={toggleMenu} />
        <nav className="c-page-header__nav" aria-label="Meny">
          <Link
            to="/om-klassrumsmaterialet/"
            className="c-page-header__nav__link"
            activeClassName="-active"
          >
            Om klassrumsmaterialet
          </Link>
          <button
            aria-label="Lyssna med browesealoud"
            title="Lyssna med browsealoud"
            type="button"
            onClick={() => window.toggleBar && window.toggleBar()}
            className="c-page-header__nav__icon"
          >
            <img
              className="c-page-header__nav__icon__image"
              src={speakerIcon}
              alt="Lyssna med browsealoud"
            />
          </button>
          <MenuButton toggle={toggleMenuHandler} toggled={toggleMenu} />
        </nav>
      </header>
      <Menu toggle={toggleMenu} setToggle={toggleMenuHandler} />
    </>
  )
}
