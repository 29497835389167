import React from "react"
const whiteLogo = require("../images/icons/dp-logo-small-white.svg")
const blackLogo = require("../images/icons/dp-logo-small-black.svg")
import Link from "gatsby-link"

export default function HeaderLogo({ modifier }) {
  return (
    <div className="c-header-logo">
      <Link to="/" activeClassName="-active">
        <img
          className="c-header-logo__img"
          src={modifier ? whiteLogo : blackLogo}
          alt="Forum för levande historia"
        />
        <span className="c-header-logo__text">Demokrati pågår</span>
      </Link>
    </div>
  )
}
