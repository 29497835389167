import * as React from "react"
import Link from "gatsby-link"
import { navigate } from "gatsby"
const angleIcon = require("../images/icons/dp-icon-arrow-link.svg")
const angleIconWhite = require("../images/icons/dp-icon-arrow-link-white.svg")

const CardSelect = props => {
  const icon = props.modifier === "menu" ? angleIconWhite : angleIcon

  const clickHandler = e => {
    if (
      !(e.target instanceof HTMLLinkElement) &&
      !(e.target instanceof HTMLAnchorElement)
    ) {
      navigate(props.to)
    }
  }

  return (
    <div
      className={
        props.modifier
          ? "c-card c-card--select c-card--" + props.modifier
          : "c-card c-card--select"
      }
    >
      <div className="c-card__content">
        <h2 className="c-card__title">
          <Link to={props.to} activeClassName="--active">
            {props.title}
            <img className="c-card__link-arrow" src={icon} alt="" />
          </Link>
        </h2>
        <p>{props.text}</p>

        {props.listheading && props.links && <h3>{props.listheading}</h3>}

        {props.links && (
          <ul className="c-card__list">
            {props.links.map((link, index) => (
              <li key={index} className="c-card__list__item">
                <Link
                  to={link.to}
                  className="c-card__list__item__link"
                  activeClassName="--active"
                >
                  {link.text}
                  <img className="c-card__link-arrow" src={icon} alt="" />
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default CardSelect
