import React from "react"
import CardSelect from "../components/card-select"
import Footer from "../components/footer"
import Link from "gatsby-link"

export default function menu({ toggle }) {
  return (
    <div
      aria-label="Meny"
      className={`c-menu-overlay ${
        toggle ? "c-menu-overlay--show" : "c-menu-overlay--hide"
      }`}
      id="menu"
      role="menu"
    >
      {toggle && (
        <section className="c-card-grid c-card-grid--menu c-main-background c-main-background--menu">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <Link
                  to="/om-klassrumsmaterialet/"
                  className="c-menu-overlay__link"
                  activeClassName="-active"
                >
                  Om klassrumsmaterialet
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <CardSelect
                  modifier="menu"
                  to="/om-demokrati"
                  title="Om demokrati"
                  text="Fördjupa elevernas förståelse för demokratins värden och principer. Korta intervjuklipp och bildspel om demokratins byggstenar."
                  links={[
                    {
                      text: "Demokratiska principer",
                      to: "/om-demokrati/demokratiska-principer",
                    },
                    {
                      text: "Röster om demokrati",
                      to: "/om-demokrati/roster-om-demokrati",
                    },
                  ]}
                />
              </div>
              <div className="col-xs-12 col-sm-4">
                <CardSelect
                  modifier="menu"
                  to="/historiska-perspektiv"
                  title="Historiska perspektiv på demokrati"
                  text="Skapa överblick och ge eleverna sammanhang genom ett historiskt perspektiv på svensk demokrati. Tidslinje med frågor, personberättelser och historiska källor."
                  links={[
                    {
                      text: "Tidslinjen",
                      to: "/historiska-perspektiv/tidslinjen-oversikt",
                    },
                    {
                      text: "Utan demokratiska rättigheter",
                      to:
                        "/historiska-perspektiv/utan-demokratiska-rattigheter",
                    },
                  ]}
                />
              </div>
              <div className="col-xs-12 col-sm-4">
                <CardSelect
                  modifier="menu"
                  to="/demokratiska-samtal"
                  title="Demokratiska samtal"
                  text="Träna elevernas demokratiska förmågor. Rättvisedilemma och kreativa övningar."
                  links={[
                    {
                      text: "Vad är rättvisa?",
                      to: "/demokratiska-samtal/vad-ar-rattvisa",
                    },
                    {
                      text: "Skapa demokratiska samtal",
                      to: "/demokratiska-samtal/skapa-demokratiska-samtal",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer modifier="c-footer--menu" />
    </div>
  )
}
